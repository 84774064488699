import React from 'react'
import styled from 'styled-components'
require('prismjs/themes/prism.css')

const Wrapper = styled.section`
  padding: 0 2em;
`

const Body = styled.div`
  font-family: Raleway;
  font-style: normal;
  font-weight: 300;
  line-height: 30px;
  font-size: 18px;

  margin: 0 auto;
  margin-top: 0em;

  @media screen and (max-width: 600px) {
    font-size: 12px;
    line-height: 16px;
  }

  h1,
  h2,
  h3 {
    font-weight: 600;
    line-height: 1.25;
    margin: 0 0 1rem 0;
    text-transform: capitalize;
  }

  h1 {
    font-size: 1.5em;
  }
  h2 {
    font-size: 1.25em;
  }
  h3 {
    font-size: 1em;
  }

  p {
    line-height: 1.6;
    margin: 0 0 2em 0;
  }

  a {
    transition: 0.2s;
    color: ${props => props.theme.colors.base};
    &:hover {
      color: ${props => props.theme.colors.highlight};
    }
  }

  del {
    text-decoration: line-through;
  }
  strong {
    font-weight: 600;
  }
  em {
    font-style: italic;
  }

  ul,
  ol {
    margin: 0 0 2em 0;
  }

  ul {
    li {
      list-style: disc;
      list-style-position: inside;
      line-height: 1.25;
      &:last-child {
        margin: 0;
      }
    }
  }

  ol {
    li {
      list-style: decimal;
      list-style-position: inside;
      line-height: 1.25;
      &:last-child {
        margin: 0;
      }
    }
  }

  hr {
    border-style: solid;
    border-color: ${props => props.theme.colors.secondary};
    margin: 0 0 2em 0;
  }

  blockquote {
    font-style: italic;
    border-left: 4px solid ${props => props.theme.colors.secondary};
    padding: 0 0 0 0.5em;
  }

  pre {
    margin: 0 0 2em 0;
    border-radius: 2px;
    background: ${props => props.theme.colors.secondary} !important;
    span {
      background: inherit !important;
    }
  }

`

const Title = styled.div`
  font-family: Raleway;
  font-style: normal;
  font-weight: 300;
  line-height: 33px;
  font-size: 28px;
  letter-spacing: 0.22px;
  text-align: center;
  margin-bottom: 1em;
  color: ${props => props.theme.colors.secondary};

  @media screen and (max-width: 600px) {
    font-size: 16px;
    line-height: 20px;
  }
`

const Subtitle = styled.div`
  font-family: Cormorant;
  font-size: 28px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.18;
  letter-spacing: 0.7px;
  text-align: center;
  color: ${props => props.theme.colors.secondary};

  @media screen and (max-width: 600px) {
    font-size: 16px;
    line-height: 20px;
  }
`

const WrapperInside = styled.section`
  max-width: 698px;
  margin: 0 auto;
  margin-top: 2em;

  margin: 0 auto;
  margin-top: 50px;
  padding: 2em 2em;
  max-width: ${props => props.theme.sizes.maxWidth};
  border: solid 1.5px ${props => props.theme.colors.secondary};
`

const PageBody = props => {
  return (
    <Wrapper>
      <WrapperInside>
        <Title>{props.title}</Title>
        <Subtitle>{props.subTitle}</Subtitle>
        <Body
          dangerouslySetInnerHTML={{
            __html: props.body.childMarkdownRemark.html,
          }}
        />
      </WrapperInside>
    </Wrapper>
  )
}

export default PageBody
